import React, { Suspense, lazy } from "react";
// import Index from "./routes";
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/style.css"
import "./assets/responsive.css"

const AppRouter = lazy(() => import('./routes'))
const App = () => {
  return (
    <Suspense fallback={<div>loding...</div>}>
      <AppRouter />
    </Suspense>
  )
}
export default App